var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"custom-filter":_vm.customFilter,"items":_vm.staffs},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("User Management")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.newItem}},[_vm._v("New User")])],1)]},proxy:true},{key:"item.Centres",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatCentre")(item.Centres))+" "),(item.Centres.length > 1)?_c('v-chip',{staticClass:"ml-0 mr-2 black--text",attrs:{"color":"blue lighten-4","label":"","small":""}},[_vm._v(" "+_vm._s(item.Centres.length)+" campuses ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editPassword(item)}}},[_vm._v("mdi-lock-question")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.filteredRoles,"item-text":"Name","item-value":"RoleID","label":"Role","rules":[
                    function () { return !!_vm.selectRole || 'This field is required'; }
                  ],"clearable":""},on:{"change":function($event){_vm.selectMultipleCentres = null; _vm.selectSingleCentre = null;}},model:{value:(_vm.selectRole),callback:function ($$v) {_vm.selectRole=$$v},expression:"selectRole"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[
                    function () { return !!_vm.editedItem.GivenName && _vm.editedItem.GivenName.length <= 45 || 'Given name must be less than 45 characters'; } ],"counter":"45","required":"","label":"Given Name"},model:{value:(_vm.editedItem.GivenName),callback:function ($$v) {_vm.$set(_vm.editedItem, "GivenName", $$v)},expression:"editedItem.GivenName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[
                    function () { return !!_vm.editedItem.Surname && _vm.editedItem.Surname.length <= 45 || 'Surname must be less than 45 characters'; } ],"counter":"45","required":"","label":"Surname"},model:{value:(_vm.editedItem.Surname),callback:function ($$v) {_vm.$set(_vm.editedItem, "Surname", $$v)},expression:"editedItem.Surname"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Short Name (This name will be printed on Good Student Award)","counter":"16","placeholder":"Mr Tommy"},model:{value:(_vm.editedItem.ShortName),callback:function ($$v) {_vm.$set(_vm.editedItem, "ShortName", $$v)},expression:"editedItem.ShortName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    function () { return !!_vm.editedItem.Username || 'This field is required'; },
                    function () { return !!_vm.editedItem.Username && _vm.editedItem.Username.length <= 20 || 'Login Username must be less than 20 characters'; } ],"counter":"20","required":"","disabled":!_vm.isNew,"prepend-icon":"mdi-account","label":"Login Username"},model:{value:(_vm.editedItem.Username),callback:function ($$v) {_vm.$set(_vm.editedItem, "Username", $$v)},expression:"editedItem.Username"}})],1),(_vm.isNew)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    function () { return !!_vm.password || 'This field is required'; } ],"prepend-icon":"mdi-lock-question","required":"","label":"Login Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e(),(_vm.allowRegionFilter)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.regions,"item-text":"Name","item-value":"RegionID","label":"Region","prepend-icon":"mdi-earth","rules":[
                    function () { return !!_vm.selectRegion || 'This field is required'; }
                  ],"clearable":""},on:{"change":function($event){_vm.selectMultipleCentres = null; _vm.selectSingleCentre = null}},model:{value:(_vm.selectRegion),callback:function ($$v) {_vm.selectRegion=$$v},expression:"selectRegion"}})],1):_vm._e(),(_vm.allowCentreFilter)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.showSingleCentre)?[_c('v-select',{attrs:{"items":_vm.filteredCentres,"item-text":"Name","item-value":"CentreID","label":"Campus","prepend-icon":"mdi-home-outline","rules":[
                      function () { return !!_vm.selectSingleCentre || 'This field is required'; }
                    ],"clearable":""},model:{value:(_vm.selectSingleCentre),callback:function ($$v) {_vm.selectSingleCentre=$$v},expression:"selectSingleCentre"}})]:(_vm.showMultipleCentres)?[_c('v-select',{attrs:{"items":_vm.filteredCentres,"multiple":"","item-text":"Name","item-value":"CentreID","label":"Campus","prepend-icon":"mdi-home-outline","rules":[
                      function () { return !!_vm.selectMultipleCentres || 'This field is required'; }
                    ],"clearable":""},model:{value:(_vm.selectMultipleCentres),callback:function ($$v) {_vm.selectMultipleCentres=$$v},expression:"selectMultipleCentres"}})]:_vm._e()],2):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogPassword),callback:function ($$v) {_vm.dialogPassword=$$v},expression:"dialogPassword"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New Password")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"passwordform",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    function () { return !!_vm.password || 'This field is required'; } ],"prepend-icon":"mdi-lock-question","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closePassword}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.savePassword}},[_vm._v("Save")])],1)],1)],1),_c('confirm-dialog',{ref:"confirm"}),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Close ")])],1),_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }