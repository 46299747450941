<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-data-table :search="search" :headers="headers" :custom-filter="customFilter" :items="staffs" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>User Management</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="newItem">New User</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.Centres`]="{ item }">
            <span>{{ item.Centres | formatCentre }}
               <v-chip v-if="item.Centres.length > 1" :color="`blue lighten-4`" class="ml-0 mr-2 black--text" label small>
                  {{item.Centres.length}} campuses
                </v-chip>
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="editPassword(item)">mdi-lock-question</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-select v-model="selectRole" :items="filteredRoles" item-text="Name" item-value="RoleID" label="Role"
                    :rules="[
                      () => !!selectRole || 'This field is required'
                    ]"
                    @change="selectMultipleCentres = null; selectSingleCentre = null;" 
                    clearable></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                    v-model="editedItem.GivenName"
                    :rules="[
                      () => !!editedItem.GivenName && editedItem.GivenName.length <= 45 || 'Given name must be less than 45 characters',
                    ]"
                    counter="45"
                    required 
                    label="Given Name"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                    v-model="editedItem.Surname" 
                    :rules="[
                      () => !!editedItem.Surname && editedItem.Surname.length <= 45 || 'Surname must be less than 45 characters',
                    ]"
                    counter="45"
                    required 
                    label="Surname"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field 
                    v-model="editedItem.ShortName" 
                    label="Short Name (This name will be printed on Good Student Award)"
                    counter="16" 
                    placeholder="Mr Tommy"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field 
                    v-model="editedItem.Username"
                    :rules="[
                      () => !!editedItem.Username || 'This field is required',
                      () => !!editedItem.Username && editedItem.Username.length <= 20 || 'Login Username must be less than 20 characters',
                    ]"
                    counter="20"
                    required 
                    :disabled="!isNew"
                    prepend-icon="mdi-account"    
                    label="Login Username"></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="isNew">
                    <v-text-field 
                    v-model="password"
                    :rules="[
                      () => !!password || 'This field is required',
                    ]"
                    prepend-icon="mdi-lock-question"
                    required  
                    label="Login Password"></v-text-field>
                  </v-col>
                  <v-col v-if="allowRegionFilter" cols="12">
                    <v-select v-model="selectRegion" :items="regions" item-text="Name" item-value="RegionID" label="Region" prepend-icon="mdi-earth" 
                    @change="selectMultipleCentres = null; selectSingleCentre = null"
                    :rules="[
                      () => !!selectRegion || 'This field is required'
                    ]"
                    clearable></v-select>
                  </v-col>
                  <v-col v-if="allowCentreFilter" cols="12">
                    <template v-if="showSingleCentre">
                      <v-select v-model="selectSingleCentre" :items="filteredCentres" item-text="Name" item-value="CentreID" label="Campus" prepend-icon="mdi-home-outline" 
                      :rules="[
                        () => !!selectSingleCentre || 'This field is required'
                      ]"
                      clearable></v-select>
                    </template>
                    <template v-else-if="showMultipleCentres">
                      <v-select v-model="selectMultipleCentres" :items="filteredCentres" multiple item-text="Name" item-value="CentreID" label="Campus" prepend-icon="mdi-home-outline" 
                      :rules="[
                        () => !!selectMultipleCentres || 'This field is required'
                      ]"
                      clearable></v-select>
                    </template>
                  </v-col>
                </v-row>
              </v-form>  
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPassword" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">New Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="passwordform" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field 
                    :rules="[
                      () => !!password || 'This field is required',
                    ]"
                    prepend-icon="mdi-lock-question" v-model="password" label="Password"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closePassword">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="savePassword">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <confirm-dialog ref="confirm"></confirm-dialog>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
        {{ snackbarText }}
        <v-btn dark text @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
      <div class="text-center">
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </v-layout>
  </v-container> 
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { RoleType } from '@/enums/Roles';
import ConfirmDialog from '@/components/ConfirmDialog'
import { httpClient } from '@/services/http-client';

export default {
  name: 'UserManagement',
  components: {
    ConfirmDialog
  },
  data() {
    return {
      dialog: false,
      dialogPassword: false,
      headers: [
        {
          text: 'Username',
          align: 'start',
          value: 'Username',
        },
        { text: 'GivenName', value: 'GivenName' },
        { text: 'Surname', value: 'Surname' },
        { text: 'Role', value: 'Role.RoleName' },
        { text: 'Campus', value: 'Centres', width: "250" },
        { text: 'Region', value: 'Region.Name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: { GivenName: '', Surname: '', ShortName: '', Username: '', Role: '', Campus: '' },
      defaultItem: { GivenName: '', Surname: '', ShortName: '', Username: '', Role: '', Campus: '' },
      overlay: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      timeout: 6000,
      selectRole: null,
      selectRegion: null,
      selectMultipleCentres: null,
      selectSingleCentre: null,
      password: '',
      isNew: false,
      search: null,
      roles: [
        { Name: 'State Director', RoleID: RoleType.STATEDIRECTOR, authorize: [RoleType.ADMIN] },
        { Name: 'Campus Director', RoleID: RoleType.CAMPUSDIRECTOR, authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR] },
        { Name: 'State Teacher', RoleID: RoleType.STATETEACHER, authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR] },
        { Name: 'Limited State Teacher', RoleID: RoleType.LIMITEDSTATETEACHER, authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR, RoleType.STATETEACHER] },
        { Name: 'Teacher', RoleID: RoleType.TEACHER, authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR, RoleType.CAMPUSDIRECTOR, RoleType.STATETEACHER] },
        { Name: 'Customer Service Support', RoleID: RoleType.CUSTOMERSERVICESUPPORT, authorize: [RoleType.ADMIN, RoleType.STATEDIRECTOR, RoleType.CAMPUSDIRECTOR] },
      ],
    }
  },
  computed: {
    filteredCentres() {
      if (this.selectRegion)
        return this.centres.filter(c => c.RegionID === this.selectRegion);
      return this.centres;
    },
    filteredRoles() {
      if (this.user)
        return this.roles.filter(r => r.authorize.includes(this.user.RoleID));
      return [];  
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New User' : 'Edit User'
    },
    showMultipleCentres () {
      return this.selectRole === RoleType.LIMITEDSTATETEACHER;
    },
    showSingleCentre () {
      return this.selectRole === RoleType.CAMPUSDIRECTOR || this.selectRole === RoleType.TEACHER || this.selectRole === RoleType.CUSTOMERSERVICESUPPORT;
    },
    ...mapGetters(['allowRegionFilter', 'allowCentreFilter']),
    ...mapState(['user', 'regions', 'centres', 'staffs'])
  },
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        this.overlay = true;
        await Promise.all([this.$store.dispatch('getStaffs'),
        this.$store.dispatch('getRegions'),
        this.$store.dispatch('getCentres')]);
      } finally {
        this.overlay = false;
      }
    },
    editItem (item) {
      this.editedIndex = this.staffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectRole = this.editedItem.RoleID;
      this.selectRegion = this.editedItem.RegionID;
      this.selectMultipleCentres = this.editedItem.Centres && this.editedItem.Centres.map(c=> c.CentreID);
      this.selectSingleCentre = this.editedItem.Centres && this.editedItem.Centres.length > 0 && this.editedItem.Centres[0].CentreID;
      this.isNew = false;
      this.dialog = true
    },
    async deleteItem (item) {
      if (await this.$refs.confirm.open('Delete', 'Are you sure you want to delete this user?', { color: 'red' })) {
        const result = await this.$store.dispatch('deleteStaff', { editedItem: item } );
        this.snackbar = true;
        if (!!result === true) {
          this.snackbarColor = "success";
          this.snackbarText = "User deleted successfully";
        } else {
          this.snackbarColor= "error";
          this.snackbarText = "Failed to delete user. Please try again";
        }
      }
    },
    close () {
      this.$refs.form.reset();
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
    },
    closePassword () {
      this.$refs.passwordform.reset();
      this.dialogPassword = false;
      this.password = null;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
    },
    async save () {
      if (this.$refs.form.validate()) {
        let result = null;
        if (this.editedIndex > -1) {
          const params = {
            Surname: this.editedItem.Surname,
            GivenName: this.editedItem.GivenName,
            ShortName: this.editedItem.ShortName,
            RoleID: this.selectRole && this.selectRole,
            CentreIDs: this.showMultipleCentres? this.selectMultipleCentres : this.selectSingleCentre && [].concat(this.selectSingleCentre),
            RegionID: this.selectRegion && this.selectRegion,
          };
          result = await this.$store.dispatch('updateStaff', { index: this.editedIndex, StaffID: this.editedItem.StaffID, params } );
        } else {
          const params = {
            Surname: this.editedItem.Surname,
            GivenName: this.editedItem.GivenName,
            ShortName: this.editedItem.ShortName,
            Username: this.editedItem.Username,
            Password: this.password,
            RoleID: this.selectRole && this.selectRole,
            CentreIDs: this.showMultipleCentres? this.selectMultipleCentres : this.selectSingleCentre && [].concat(this.selectSingleCentre),
            RegionID: this.selectRegion && this.selectRegion,
          };
          result = await this.$store.dispatch('saveStaff', { params } );
        }
        this.snackbar = true;
        if (!!result === true) {
          this.snackbarColor = "success";
          this.snackbarText = "User created successfully";
        } else {
          this.snackbarColor= "error";
          this.snackbarText = "Failed to create user. Username already exist.";
        }
        this.close();
      }
    },
    newItem() {
      this.isNew = true;
      this.dialog = true;
      this.selectRegion = null;
      this.selectMultipleCentres = null;
      this.selectSingleCentre = null;
    },
    editPassword(item) {
      this.dialogPassword = true;
      this.editedIndex = this.staffs.indexOf(item);
      this.editedItem = item;
      this.password = null;
    },
    async savePassword() {
      if (this.$refs.passwordform.validate()) {
        const response = await httpClient.put(`/staff/password/${this.editedItem.StaffID}`, {Password: this.password});
        this.snackbar = true;
        if (response && response.status === 200) {
          this.snackbarColor = "success";
          this.snackbarText = "Password updated successfully";
        } else {
          this.snackbarColor= "error";
          this.snackbarText = "Failed to update password. Please try again";
        }
        this.closePassword();
      }
    },
    customFilter (value, search, item) {
      return value != null &&
        search != null &&
        ( value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          this.$options.filters.formatCentre(item.Centres).toString().indexOf(search) !== -1
        )
    },
  },
}
</script>